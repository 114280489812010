th,td {
    text-align: center;
    vertical-align: middle;
}

th{
    font-weight: bold;
}

.first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.icon-sort{
    color: white;
}
.icon-sort:hover{
    color: #00338D;
}
