.text-input {
    cursor: pointer;
    color:grey;
    font-size: 13px;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.file-input {
  height: 0%;
  right: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0%;
}

.btn-deliverable{
  width: 100px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
}

.label-deliverable-setting{
  font-size: 13px;
  color: #393a3c;
  font-weight: 600;
}

.deliverable-tooltip{
  background-color: black;
  color : white;
  border-color: #ddd;
  opacity: 1;
}