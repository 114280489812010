.navbar{
    
    background-size: cover;
}
.img-logo{
  width: 25px;
}
.header-text{
    margin-left: 10px;
    color: #00338d;
    font-weight: bold;
    font-size: 16px;
    vertical-align: bottom;
}
.container{
  margin-left: 30px !important;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
