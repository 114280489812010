.caption{
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
}


.menu-tab{
    text-align: left;
    height: 44px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right:10px;
    padding-left: 10px;
    cursor: pointer;
}

.menu-tab.active{
    
    background-color: #009FDA;
    color: white;
}
    .menu-tab.active img {
        filter: brightness(0) invert(1);
    }

    .menu-tab:hover {
        background-color: #009FDA;
        color: white;
        cursor: pointer;
    }

        .menu-tab:hover img {
            filter: brightness(0) invert(1);
        }


.scrollbar-auto {
    overflow-y: auto;
}

