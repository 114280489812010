
.autocomplete {
    width: 100%;
    position: relative;
  }
  
  .autocomplete > .wrapper {
    /*position: relative;*/
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    background-color: white;
    color: #212121;
    font-size: 16px;
    font-family: var(--bs-font-tahoma);
    z-index: 0;
  }

  .wrapper-box{
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .wrapper-no-border {
    text-align: left;
    border: none;
    border-radius: 0;
  }
  
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-top: none;
    z-index: 99;
    right: 0;
    left: 0;
    overflow-y: auto;
    max-height: 100px;
  }
  
  .autocomplete-items div {
    min-height: 30px;
    font-size: 0.9rem;
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }

  .autocomplete-items div:hover {
    color: white;
    background-color: #009FDA;
  }
  
  .autocomplete-section {
    min-height: 34px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .autocomplete-search-box{
    width: 90%;
    font-size: 0.9rem;
    border: none;
  }

  .autocomplete-search-box:focus{
    outline: none;
  }
  .autocomplete-search-box-border-bottom{
    font-size: 0.9rem;
    width: 90%;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .autocomplete-search-box-border-bottom:focus{
    outline: none;
  }

  .autocomplete-search-icon {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
   /* margin: 0 0 0 16px; */
    fill: grey;
  }
  
  .autocomplete-clear-icon {
    margin: 3px 14px 0 0;
  }