
.portfolio-stage{
  vertical-align: middle;
  font-size: 12px;
  /*font-weight: 600;*/
}
.portfolio-stage.active{
  font-weight: bold;
  color: #3F51B5;
  cursor: pointer;
}

.portfolio-stage-head{
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
}

