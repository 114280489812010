.infographic-table{
    border-collapse: separate;
    border-spacing: 3;
    width: 50%;
    border-color: white;
}

.infographic-table th {
    height: 50px;
    text-align: center;
    text-decoration: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.infographic-table td {
    border: solid 1px #00338D;
    padding: 0;
}
  
.infographic-table td:first-child { 
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
    padding: 10px;
}

.infographic-top-div{
    margin-top: -35px;
}

.infographic-bottom-div{
    height: 10px;
}

