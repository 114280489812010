.inform-section{
    border: 4px solid #ccc;
    padding: 25px;
}

.inform{
    font-size: 14px;
    padding-bottom: 10px;
}

.inform-top{
    font-size: 14px;
    padding: 20px;
    border-top: 1px solid #ccc;
}
.inform-bottom{
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    min-height: 65px;
}

.inform-input{
    height: 35px;
}

.inform-progress{
    margin-left: 40px;
}

.resource-table > :not(caption) > * > *{
    padding: 0 !important;
    color: #676565;
}

.inform-header{
    font-size: 25px;
    font-weight: bold;
    color: #234461;
}

.icon-inform-edit{
    width: 36px;
    position: absolute;
    left: -25px;
    top: -22px;
}

.topic-head{
    color:#009FDA ;
    font-weight: bold;
    padding-bottom: 5px;
  }

.block-blue{
    background-color: #deedf5;
}

.tab-btn{
    width: 150px;
    height: 43px;
    border: 2px solid white;
    font-weight: bold;
    background-color: #e4e9f4;
}

.status-info{
    padding: 15px;
    border: 2px solid  #bbb;
}

.information-tooltip{
    background-color: white;
    color : black;
    border-color: #ddd;
    opacity: 0.8;
  }

  /*
select option{
    color: #212529;
}

select option[value=0]{
    color: grey
}
*/
