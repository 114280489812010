@charset "UTF-8";
.overalltable .shadow1 {
    /*
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.5), 0 10px 10px rgba(0, 0, 0, 0.2);
    */
    margin-bottom:30px;
    text-align: -webkit-center;
    padding: 0px;
    padding-top: 25px;
    padding-bottom: 20px;
    box-shadow: 0px 0 0px -6px rgba(31, 73, 125, 0.8), -2px 0 20px -4px rgba(31, 73, 125, 0.8);
    border-radius:15px;
}
.overalltable .col-color-0 {
    background-color: #e9f9ff !important;
}

.overalltable .col-circle-color-out0 {
    background: -webkit-linear-gradient(left top, #A0D0FF 0%, #009FDA 100%);
}

.overalltable .col-circle-color-out1 {
    background: -webkit-linear-gradient(left top, #6699EE 0%, #00338D 100%);
}

.overalltable .col-circle-color-out2 {
    background: -webkit-linear-gradient(left top, #FFCC66 0%, #CF6F00 100%);
}

.overalltable .col-circle-color-out3 {
    background: -webkit-linear-gradient(left top, #DDFF66 0%, #7AB800 100%);
}

.overalltable .col-circle-color-out4 {
    background: -webkit-linear-gradient(left top, #CCCCCC 0%, #747678 100%);
}

.overalltable .col-circle-color-out5 {
    background: -webkit-linear-gradient(left top, #FFAA99 0%, #D52B1E 100%);
}

    .overalltable .header-block-down2 .minor-label {

        font-size: 11px;
        color: #BBBBBB;
        font-style:italic;
    }

.overalltable .block-col1 .small-font {
    font-size: 11px;
}
.overalltable .block-col1 {
    display: flex;
    background-color: #e9f9ff !important;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    max-height: 50px;
    font-size: 13px;
    color: #777777;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
    .overalltable .block-col1:hover, .overalltable .block-col1:focus, .overalltable .block-col1:active {
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        color: #222222;
    }


/* Grow Shadow */
.hvr-grow-shadow {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

    .hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        color: #770000;
    }


.overalltable .block-col {
    display: flex;
    justify-content: center;
    background-color: white;
    padding-top: 13px;
    padding-bottom: 16px;
    max-height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #00338D;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

    .overalltable .block-col:hover, .overalltable .block-col:focus, .overalltable .block-col:active {
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        color: #00114D;
    }

.overalltable .block-col-last {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 0px;
    max-height: 50px;
    font-size: 18px;
    color:#DD0000;
    font-weight: bold;
}
.overalltable .block-col a {
    font-size: 18px;
    font-weight: bold;
    color:inherit;
}
.overalltable .block-col-last a {
    color:inherit;
    font-weight: bold;
}
.overalltable .block-down {
    display: flex;
    justify-content: center;
}
    .overalltable .block-down div {
        background-color: #89cd2c;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        position: absolute;
        margin-top: -10px;
    }

.overall-legend img{
    width: 18px;
}

/* Sweep To Right */
.hvr-sweep-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

    .hvr-sweep-to-right:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #c9e9ff;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
        color: white;
    }

        .hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

/* Sweep To Right */
.hvr-sweep-to-right2 {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

    .hvr-sweep-to-right2:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #e9f9ff;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .hvr-sweep-to-right2:hover, .hvr-sweep-to-right2:focus, .hvr-sweep-to-right2:active {
        color: white;
    }

        .hvr-sweep-to-right2:hover:before, .hvr-sweep-to-right2:focus:before, .hvr-sweep-to-right2:active:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }


/* Sweep To Right */
.hvr-sweep-to-right3 {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

    .hvr-sweep-to-right3:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffeeee;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }


        .hvr-sweep-to-right3:hover:before, .hvr-sweep-to-right3:focus:before, .hvr-sweep-to-right3:active:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
